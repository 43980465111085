import Cookies from 'js-cookie';
import Setting from '@/setting';

const cookies = {};
/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} cookieSetting cookie setting
 */
cookies.set = function (name = 'default', value = '', cookieSetting = {}) {
    const currentCookieSetting = {
        expires: Setting.cookiesExpireDays
    };
    Object.assign(currentCookieSetting, cookieSetting);
    Cookies.set(Setting.cookiesPrefix + `-${name}`, value, currentCookieSetting);
};

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function (name = 'default') {
  if (name === 'token') {
    const bridge = Setting.appBridge();
    if (bridge && bridge.getToken) {
      const token = bridge.getToken();
      if (token) {
        return token;
      }
    }
  }
  return Cookies.get(Setting.cookiesPrefix + `-${name}`);
};

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function () {
    return Cookies.get();
};

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function (name = 'default') {
    return Cookies.remove(Setting.cookiesPrefix + `-${name}`);
};

export default cookies;
