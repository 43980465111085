import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import util from '@/libs/util';
import i18n from '@/i18n';
import Home from '@/views/home.vue'
import { httpSync, httpGet } from '@/api/data';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue'),
    meta: {
      label: i18n.t('nav.about'),
      depth: 0
    }
  },
  {
    path: '/duty',
    name: 'duty',
    component: () => import('@/views/duty.vue'),
    meta: {
      label: i18n.t('nav.duty'),
      depth: 0
    }
  },
  // user info
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      label: i18n.t('nav.login'),
      depth: 100,
      keepNav: true,
      isLogin: true
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user.vue'),
    meta: {
      label: i18n.t('nav.user'),
      depth: 99,
      keepNav: true,
      login: true
    }
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/report.vue'),
    meta: {
      label: i18n.t('nav.report'),
      depth: 99,
      keepNav: true,
      login: true
    }
  },
  // bbs struct
  {
    path: '/forum',
    name: 'forum',
    component: () => import('@/views/forum.vue')
  },
  {
    path: '/topic',
    name: 'topic',
    component: () => import('@/views/topic.vue'),
    meta: {
      login: true
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin.vue'),
    meta: {
      login: true,
      label: i18n.t('nav.admin'),
      depth: 0
    }
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function doBeforeEach (to, from, next) {
  let redirectLogin = false;
  delete from.params.from;
  to.params.from = { ...from }
  if (to.name === 'admin') {
    if (store.state.inSoga) {
      // Soga瀏覽器中不允許進入管理
      if (from.path === '/') {
        next(from);
      } else {
        next(false);
      }
      return;
    }
    if (!util.canAdmin(store.state.user)) {
      // 检查权限组
      if (from.path === '/') {
        redirectLogin = true;
      } else {
        next(false);
        return;
      }
    } else if (util.hasLogin(store)) {
      // 检查登录时间
      if (from.meta.isLogin && store.state.loginTime > from.meta.time) {
        store.commit('enterAdmin', true);
      } else if (store.state.admin.loginTime < new Date().getTime()) {
        redirectLogin = true;
      }
    } else {
      redirectLogin = true;
    }
    if (redirectLogin && from.meta.isLogin) {
      next(false);
      return;
    }
  } else if (from.meta.isLogin) {
    // 从login返回
    if (to.meta.isLogin) {
      next(false);
      return;
    }
    for (const k in from.params) {
      to.params[k] = from.params[k];
    }
    store.commit('popNav');
  } else if (to.meta.login && !util.hasLogin(store)) {
    redirectLogin = true;
  }
  if (redirectLogin) {
    next({
      name: 'login',
      params: {
        target: to
      }
    });
    return;
  }

  next();
}

router.beforeEach((to, from, next) => {
  if (!store.state.groups) {
    const token = util.cookies.get('token');
    if (token) {
      store.commit('readUserInfo', true);
      httpSync([
        httpGet('/v1/bbs/groups'),
        httpGet('/v1/bbs/user')
      ], (resG, resU) => {
        store.commit('saveGroups', resG);
        store.commit('saveUserInfo', resU);
      }).catch(() => {
        store.commit('saveUserInfo', 'default');
      }).finally(() => {
        store.commit('readUserInfo', false);
        doBeforeEach(to, from, next);
      });
    } else {
      httpGet('/v1/bbs/groups').then((resG) => {
        store.commit('saveGroups', resG);
      }).catch(() => {
      }).finally(() => {
        doBeforeEach(to, from, next);
      });
    }
    return;
  }
  doBeforeEach(to, from, next);
})

router.afterEach((to, from) => {
  to.meta.time = new Date().getTime();
  if (to.meta.keepNav) {
    if (from.meta.depth === 0) {
      store.commit('nav', to);
    } else {
      store.commit('pushNav', to);
    }
  } else if (to.meta.label) {
    store.commit('nav', to);
  } else {
    store.commit('nav');
  }
})

export default router
