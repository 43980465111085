import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI, { Message, Notice } from 'view-design'
import 'view-design/dist/styles/iview.css'

import Setting from '@/setting'
// 多语言
import i18n from './i18n';

if (store.state.inSoga) {
  // eslint-disable-next-line no-unused-expressions
  import('@/assets/css/soga.css');
}

Setting.titleSuffix = i18n.t('page.title');
Setting.errorModalTitle = i18n.t('common.alert_title');
// 初始化错误描述字符串
const errors = i18n.messages.error;
for (const k in errors) {
  Setting.errorDescriptions[k] = errors[k];
}

Setting.showError = (message, code) => {
  let showMsg = message;
  if (code) {
    showMsg = '[' + code + ']' + message;
  }
  console.error(showMsg);
  // 显示提示，可配置使用 iView 的 $Message 还是 $Notice 组件来显示
  if (Setting.errorModalType === 'Message') {
    Message.error({
      content: showMsg,
      duration: Setting.modalDuration
    });
  } else if (Setting.errorModalType === 'Notice') {
    Notice.error({
      title: Setting.errorModalTitle,
      desc: showMsg,
      duration: Setting.modalDuration
    });
  }
}

Vue.config.productionTip = false
Vue.use(ViewUI)
new Vue({
  router,
  store,
  i18n,
  ViewUI,
  render: h => h(App),
  created () {
  }
}).$mount('#app')
