import Vue from 'vue'
import Vuex from 'vuex'
import util from '../libs/util';

Vue.use(Vuex)

function pushNavItem (state, item) {
  let nav = null;
  if (typeof item === 'string') {
    return false;
  }
  if (Array.isArray(item)) {
    if (item.length < 2 || typeof item[0] !== 'string') {
      return false;
    }
    nav = {
      label: item[0],
      route: item[1]
    };
  } else if (typeof item === 'object') {
    if (item.fullPath) {
      if (!item.meta.label) {
        return false;
      }
      item = {
        label: item.meta.label,
        route: item
      }
    }
    if (!item.label) {
      return false;
    }
    if (!item.route) {
      return false;
    }
    nav = item;
  }
  if (nav == null) {
    return false;
  }
  if (typeof nav.route === 'string') {
    nav.route = {
      fullPath: nav.route
    }
  }
  if (nav.route.name) {
    nav.key = nav.route.name;
  } else if (nav.route.path) {
    nav.key = nav.route.path;
  } else {
    const pos = nav.route.fullPath.lastIndexOf('?');
    if (pos < 0) {
      nav.key = nav.route.fullPath;
    } else {
      nav.key = nav.route.fullPath.substring(0, pos);
    }
  }

  let n = state.nav.length;
  for (let i = 0; i < n; i++) {
    if (state.nav[i].key === nav.key) {
      state.nav.splice(i);
      n--;
      break;
    }
  }
  if (n > 0) {
    state.nav[n - 1].isLast = false;
  }
  nav.isLast = true;
  state.nav.push(nav);
  return true;
}

function pushNavItems (state, items) {
  if (Array.isArray(items)) {
    for (let i = 0, n = items.length; i < n; i++) {
      if (!pushNavItem(state, items[i])) {
        break;
      }
    }
  } else if (items) {
    pushNavItem(state, items);
  }
}

export default new Vuex.Store({
  state: {
    locale: '',
    admin: {
      server: {
        url: null
      },
      // 登入admin的时间
      loginTime: 0
    },
    // 地区码
    states: null,

    // 获取用户信息标记：true正在获取，false已获取
    gettingUser: null,
    // 登录时间
    loginTime: 0,
    // 用户信息
    user: null,
    // 所有分组权限
    groups: null,
    // 导航
    nav: [],
    // 是否处于Soga浏览器中
    inSoga: !!(window && window.HTMLOBJ)
  },
  mutations: {
    setLocale (state, locale) {
      state.locale = locale;
    },
    saveGroups (state, groups) {
      state.groups = groups;
    },
    login (state, rsp) {
      state.admin.loginTime = 0;
      if (rsp) {
        const expire = new Date();
        state.loginTime = expire.getTime();
        expire.setTime(rsp.expires_in * 1000);
        const setting = {
          expires: expire
        };
        util.cookies.set('token-type', rsp.token_type, setting);
        util.cookies.set('token', rsp.access_token, setting);
      } else {
        state.loginTime = 0;
        util.cookies.remove('token');
        this.commit('saveUserInfo', null);
      }
    },
    readUserInfo (state, flag) {
      state.gettingUser = !!flag;
    },
    saveUserInfo (state, user) {
      if (typeof user !== 'object') {
        if (user !== 'default') { return; }
        const info = sessionStorage.getItem('login');
        if (info) {
          user = JSON.parse(info);
          if (user) {
            state.user = user;
            return;
          }
        }
        user = null;
      }
      state.user = user;
      if (user) {
        sessionStorage.setItem('login', JSON.stringify(user));
      } else {
        sessionStorage.removeItem('login');
      }
    },
    enterAdmin (state, flag) {
      if (flag) {
        // 10分钟内有效
        state.admin.loginTime = new Date().getTime() + 10 * 60 * 1000;
      } else {
        state.admin.loginTime = 0;
      }
    },
    saveStates (state, states) {
      state.states = states;
    },
    nav (state, payload) {
      state.nav.splice(0);
      pushNavItems(state, payload);
    },
    pushNav (state, payload) {
      pushNavItems(state, payload);
    },
    popNav (state) {
      const n = state.nav.length;
      if (n > 0) {
        state.nav.pop();
        if (n > 1) {
          state.nav[n - 2].isLast = true;
        }
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
