import request, { sync } from '@/plugins/request';
import axios from 'axios';

export const httpPost = (url, data, cancelToken, ex) => {
    return request({
        url: url,
        data: data,
        method: 'post',
        cancelToken: cancelToken,
      ex
    })
}

export const httpGet = (url, cancelToken, ex) => {
    return request({
        url: url,
        method: 'get',
        cancelToken: cancelToken,
      ex
    })
}

export const httpGetParams = (url, params, cancelToken, ex) => {
    return request({
        url: url,
        method: 'get',
        params: params,
        cancelToken: cancelToken,
      ex
    })
}

export const httpPostFile = (url, data, cancelToken, ex) => {
    return request({
        url: url,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: data,
        method: 'post',
        cancelToken: cancelToken,
      ex
    })
}

export const httpPostFileProgress = (url, data, cancelToken, onUploadProgress, ex) => {
    return request({
        url: url,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: data,
        onUploadProgress: onUploadProgress,
        method: 'post',
        cancelToken: cancelToken,
      ex
    })
}

export const httpDelete = (url, data, cancelToken, ex) => {
    return request({
        url: url,
        data: data,
        method: 'delete',
        cancelToken: cancelToken,
      ex
    })
}

export const httpPut = (url, data, cancelToken, ex) => {
    return request({
        url: url,
        data: data,
        method: 'put',
        cancelToken: cancelToken,
      ex
    })
}

export const httpSync = (requests, callback) => {
  return sync(requests, callback);
}

export const CancelToken = axios.CancelToken
