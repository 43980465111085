<template>
  <span>
    <span v-if="!nomaster && user.master===1" class="master-supper">總版</span>
    <span v-else-if="!nomaster && user.master" class="master">版主</span>
    <router-link v-if="!nolink && !inSoga" :to="'/user?id='+user.id">{{showName}}
    </router-link>
    <span v-else>{{showName}}</span>
  </span>
</template>

<script>
  export default {
    name: 'UserName',
    props: {
      user: {
        id: Number,
        nick: String,
        uname: String
      },
      nolink: Boolean,
      nomaster: Boolean
    },
    data () {
      return {
        inSoga: this.$store.state.inSoga
      }
    },
    computed: {
      showName () {
        return this.user.nick ? this.user.nick : this.user.uname;
      }
    }
  }
</script>

<style scoped>
</style>
