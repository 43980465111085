<script>
  export default {
    name: 'SogaLink',
    data () {
      return {
        inSoga: this.$store.state.inSoga
      }
    },
    render (h) {
      const def = this.$slots.default;
      if (!def || def.length === 0) {
        return '';
      }
      if (this.inSoga) {
        if (def.length === 1) {
          if (def[0].children) {
            return def[0].children;
          }
          const opts = def[0].componentOptions;
          if (opts) {
            return opts.children;
          }
          return '';
        }
        return def.slice(1);
      }
      return def;
    }
  }
</script>
