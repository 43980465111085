import store from '@/store';
import axios from 'axios';
import Setting from '@/setting';
import util from '@/libs/util';

// 创建一个错误
function errorCreate (msg, code) {
  const err = new Error(msg)
  err.code = code
  return err
}

// 统一错误
function processError (response, error, message, code) {
  if (typeof code === 'string') {
    code = parseInt(code);
  }
  if (error) {
    if (!response) {
      response = error.response;
    }
    if (message) {
      error.message = message;
    } else {
      if (!error.message) {
        error.message = 'Unknown';
      }
      message = error.message;
    }
    if (isNaN(code)) {
      code = response ? response.status : 601;
    }
    error.code = code;
    if (error.__CANCEL__) {
      // user cancel
      return null;
    }
  } else {
    if (!message) {
      message = 'Unknown';
    }
    if (isNaN(code)) {
      code = 600;
    }
    error = errorCreate(message, code)
  }

  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    console.log(error);
  }

  // 检查错误是否显示
  let show = true;
  // 获取请求的扩展数据
  const ex = (!!response && !!response.config) ? response.config.ex : null;
  let noTrans = false;
  // 检查错误是否显示为用户消息
  if (ex != null && ex.errors) {
    const type = typeof ex.errors;
    if (type === 'number') {
      show = ex.errors !== code;
    } else if (Array.isArray(ex.errors)) {
      if (ex.errors.length === 0) {
        // 空数组表示全忽略
        show = false;
      } else {
        show = ex.errors.indexOf(code) < 0;
      }
    } else {
      let userMsg = null;
      if (type === 'string') {
        userMsg = ex.errors;
      } else if (type === 'object') {
        userMsg = ex.errors[code];
      }
      if (userMsg) {
        message = userMsg;
        noTrans = true;
      } else if (userMsg !== undefined) {
        show = false;
      }
    }
  }
  if (!noTrans && Setting.errorDescriptions) {
    const localMsg = Setting.errorDescriptions[code];
    if (localMsg) {
      message = localMsg;
    }
  }
  // 显示错误
  if (show) {
    Setting.showError(message, code);
  }
  return errorCreate(message, code);
}

// 创建一个 axios 实例
const service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: Setting.apiTimeout * 1000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (store.state.admin.server.url !== null && store.state.admin.server.url !== config.baseUrl) {
      config.baseURL = store.state.admin.server.url;
    }
    // demo data
    if (Setting.demo === 2) {
      let path = config.url;
      let pos = path.lastIndexOf('?');
      if (pos > 0) {
        path = path.substring(0, pos);
      }
      pos = path.lastIndexOf('/');
      if (pos < 0) pos = 0;
      if (path.indexOf('.', pos) < 0) {
        config.url = path + '.json';
        config.method = 'get';
      }
    }
    // 在请求发送之前做一些处理
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    let auth = '';
    const token = util.cookies.get('token');
    if (token) {
      auth = util.cookies.get('token-type');
      if (!auth) {
        auth = 'Bearer';
      }
      auth += ' ' + token;
    }
    config.headers.Authorization = auth;
    return config;
  },
  error => {
    // 发送失败
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const { result } = dataAxios;
    // 根据 code 进行判断
    if (result === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口
      return dataAxios;
    } else {
      /*
      if (result !== 0) {
        if (result === 1010000) {
          // 登出
          store.commit('login', null);
        }
        const err = new Error(dataAxios.msg);
        errorLog(err);
      }
      return dataAxios;
       */
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (result) {
        case 0:
          // [ 示例 ] code === 0 代表没有错误
          return dataAxios.data
        default:
          // 不是正确的 code
          throw processError(response, null, dataAxios.msg, result);
      }
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '請求錯誤'; break;
        case 401: error.message = '未授權，請登錄'; break;
        case 403: error.message = '拒絕訪問'; break;
        case 404: error.message = `請求地址出錯: ${error.response.config.url}`; break;
        case 408: error.message = '請求超時'; break;
        case 500: error.message = '服務器內部錯誤'; break;
        case 501: error.message = '服務未實現'; break;
        case 502: error.message = '網關錯誤'; break;
        case 503: error.message = '服務不可用'; break;
        case 504: error.message = '網關超時'; break;
        case 505: error.message = 'HTTP版本不受支持'; break;
        default: break;
      }
    }
    error = processError(null, error);
    return Promise.reject(error)
  }
);

export const sync = (requests, callback) => {
  return axios.all(requests).then(axios.spread(callback));
}

export const CancelToken = axios.CancelToken

export default service;
