import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store/index';

import Setting from '@/setting';
import Languages from '@/i18n/locale';

let locale = store.state.locale;
if (!locale) {
  if (Setting.i18n.auto) {
// 使用浏览器语言
    const lang = (navigator.language || navigator.browserLanguage).toLowerCase();
    for (const key in Languages) {
      if (lang === key.toLowerCase()) {
        locale = key;
        break;
      }
    }
  }
  if (!locale && Setting.i18n.default) {
    const lang = Setting.i18n.default;
    for (const key in Languages) {
      if (lang === key) {
        locale = key;
        break;
      }
    }
  }
  if (locale) {
    store.commit('setLocale', locale);
  } else {
    console.assert('请指定默认语言!');
  }
}

Vue.use(VueI18n);

export default new VueI18n({
    locale,
    messages: Languages
});
