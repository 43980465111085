export default {
    locale: 'zh-CN',
    language: '简体中文',
    nav: {
      home: '首页',
      about: '关于我们',
      duty: '免责声明',
      login: '登录',
      user: '用户',
      report: '举报',
      admin: '后台管理'
    },
    error: {
      400: '请求错误',
      401: '未授权，请登录',
      403: '拒绝访问',
      404: '请求错误',
      408: '请求超时',
      500: '服务器内部错误',
      501: '服务器内部错误',
      502: '网关错误',
      503: '服务不可用',
      504: '网关超时',
      505: 'HTTP版本不受支持',

      1000000: '获取数据失败！',
      1010000: '未登录！',
      1011000: '令牌失效！',
      1012000: '无权访问！',

      // 1020000: '参数错误！',
      1020003: '密码格式不正确！',
      1020004: '帐号未注册！',
      1020005: '密码错误！',

      1020014: '验证码错误！',
      1020015: '邮箱地址错误！',
      1020022: 'SN碼错误！',
      1020023: 'SN已經被使用！',
      1020024: '没有绑定SN！',
      1020025: '授权過期！',

      1021000: '文件上传错误！',
      1021002: '文件不存在！',

      1100000: '服务器异常，请重试！',
      1100001: '操作超时！',

      1200000: '服务器维护中！'
    },
    app: {
      copyright: 'Copyright © 2021-2022 左左右右 版权所有',
      qc_scan: '扫码接入',
      qc_fail: '获取失败！'
    },
    page: {
      title: '',
      home: {
        welcome_new: '欢迎新会员',
        lb_topic: '主题',
        lb_post_num: '帖数'
      },
      login: {
        login: '登录',
        forgot: '忘记密码?',
        state_code: '区  号：',
        mobile: '手机号：',
        pwd: '密  码',
        no_statecode: '请选择区号！',
        no_mobile: '请填写手机号！',
        no_pwd: '请输入密码！',
        login_fail: '登入失败！'
      },
      user: {
        title: '用户信息',
        tip_head: '点击更换头像',
        title_head: '更换头像',
        tip_nick: '点击更新昵称',
        lb_uname: '帐号',
        lb_nick: '昵称',
        lb_sex: '性别',
        v_sex_m: '男',
        v_sex_f: '女',
        lb_last_ip: '最后登录IP'
      },
      publish: {
        tip_topic_type: '请选择主题分类！',
        tip_topic_title: '标题不能为空！',
        tip_post_content: '内容不能为空！',
        tip_post_toolarge: '内容太大！',

        share_url_tip: '分享链接',
        share_name_tip: '标签',
        share_icon_tip: '图标URL',

        btn_modify: '修改',
        btn_publish: '发表'
      },
      forum: {
        btn_topic: '发帖',
        lb_publish_topic: '发表主题'
      },
      topic: {
        lb_reply: '回复',
        lb_edit_post: '修改帖子',
        lb_review: '查看',
        v_ts: '楼主',
        v_post_deleted: '内容已删除',
        lb_edit_at: '修改于',
        lb_del_at: '删除于',
        alert_del_topic: '删除主题将同时删除所有回复！',
        btn_reply: '回复',
        btn_top: '置顶',
        btn_move: '移动',
        btn_reply_topic: '回复主题',
        lb_post: '帖子',
        lb_topic: '主题',
        lb_score: '积分',
        lb_reg_time: '注册时间',
        lb_alive_time: '在线时间'
      },
      report: {
        alert_title: '提交成功',
        alert_content: '感谢您为本论坛的支持，我们将尽快核查处理！'
      },
      nodata: '没有数据',
      data_loading: '正在努力加载...',
      no_auth_opt: '对不起，您无权执行此操作！',
      no_auth_view: '对不起，您无权查看此内容！',
      page_prev: '上一页',
      page_next: '下一页',
      lh_order_new: '最新',
      lh_order_hot: '热门',
      lh_topic: '主题',
      lh_author: '作者',
      lh_stats: '回复/查看',
      lh_last: '最后回复'
    },
    common: {
      alert_title: '温馨提示',
      confirm_delete: '确认删除？',
      today: '今日',
      yesterday: '昨日',
      topic: '主题',
      post: '帖子',
      user: '用户',
      master: '版主',
      logining: '正在登入...',
      btn_save: '保存',
      btn_edit: '修改',
      btn_del: '删除',
      btn_report: '举报',
      tip_del_ok: '删除成功！',
      tip_del_fail: '删除失败！'
    },
    admin: {
      logout: '退出管理',
      menu_forum: '版块',
      menu_topic: '主题/帖子',
      menu_user: '用户',
      menu_group: '用户组'
    }
}
