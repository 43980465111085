import cookies from './util.cookies';
import log from './util.log';
import db from './util.db';

import Setting from '@/setting';

const util = {
    cookies,
    log,
    db
};

function tTitle (title = '') {
    if (window && window.$t) {
        if (title.indexOf('$t:') === 0) {
            return window.$t(title.split('$t:')[1]);
        } else {
            return title;
        }
    } else {
        return title;
    }
}

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
util.title = function ({ title, count }) {
    title = tTitle(title);
    let fullTitle = title ? `${title} - ${Setting.titleSuffix}` : Setting.titleSuffix;

    if (count) fullTitle = `(${count}条消息)${fullTitle}`;
    window.document.title = fullTitle;
};

// 检查登录。此处依据token的过期时间
util.hasLogin = function (store) {
  const token = this.cookies.get('token');
  if (token) {
    return !!store.state.user;
  }
  store.commit('saveUserInfo', null);
  return false;
};

util.isAdmin = function (user) {
  if (user) {
    return user.gid === 1;
  }
  return false;
}
util.isSupper = function (user) {
  if (user) {
    return this.isAdmin(user) || user.gid === 2;
  }
  return false;
}
util.canAdmin = function (user) {
  if (user) {
    return this.isSupper(user) || user.gid === 4;
  }
  return false;
}
util.checkMaster = function (user, masterIds) {
  if (user) {
    if (this.isSupper(user)) {
      user.master = 1;
    } else if (user.gid === 4 && masterIds) {
      user.master = masterIds.indexOf(user.id) >= 0;
    }
  }
}
util.userAuth = function (state, forum) {
  const user = state.user;
  const groups = state.groups;
  let gid = user ? user.gid : 0;
  if (this.canAdmin(user)) {
    // 具有管理身份
    return groups[gid];
  }
  if (forum) {
    // 检查版主(0遊客，7禁止)
    if (gid > 0 && gid !== 7 && forum.masters) {
      for (const m in forum.masters) {
        if (m.id === user.id) {
          // 提升為版主權限
          gid = 4;
          break;
        }
      }
    }
    // 合并版块权限
    if (forum.accession && forum.access) {
      const access = forum.access[gid];
      if (access) {
        const auth = { ...groups[gid] };
        for (const i in access) {
          auth[i] = access[i];
        }
        return auth;
      }
    }
  }

  return groups[gid];
}
util.truncateText = function (text, len, suffix) {
  if (text.length > len) {
    if (text.codePointAt(len - 1) > 0xFFFF) {
      // 多字节字符
      text = text.substring(0, len - 1);
    } else {
      text = text.substring(0, len);
    }
    if (suffix !== false) {
      if (!suffix) {
        suffix = '...';
      }
    }
    text += suffix;
  }
  return text;
}
util.base64ImgToFile = function (urlData, fileName) {
  if (urlData.indexOf('data:') !== 0) {
    return null;
  }
  const pos = urlData.indexOf(';base64,');
  if (pos < 0) {
    return null;
  }
  const fileType = urlData.substring(5, pos);
  urlData = urlData.substring(pos + 8);

  const bytes = window.atob(urlData); // 转换为byte
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length);
  const ia = new Int8Array(ab);
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  // 转换成文件，添加文件的type，name，lastModifiedDate属性
  const blob = new Blob([ab], { type: fileType });
  blob.lastModifiedDate = new Date();
  if (!fileName) {
    let ext = '';
    if (fileType === 'image/png') {
      ext = '.png';
    } else if (fileType === 'image/jpg' || fileType === 'image/jpeg') {
      ext = '.jpg';
    } else if (fileType === 'image/gif') {
      ext = '.gif';
    } else if (fileType === 'image/bmp') {
      ext = '.bmp';
    }
    fileName = blob.lastModifiedDate.getTime() + ext;
  }
  blob.name = fileName;
  return blob;
}

util.showTimeStamp = function (unixTimeStamp, showTime, maxDays) {
  if (!unixTimeStamp) {
    return '';
  }
  if (typeof unixTimeStamp === 'string') {
    const ms = Date.parse(unixTimeStamp);
    if (!ms) {
      return unixTimeStamp;
    }
    unixTimeStamp = Math.floor(ms / 1000);
  } else if (typeof unixTimeStamp !== 'number') {
    return unixTimeStamp;
  }
  if (typeof maxDays !== 'number') {
    maxDays = 3;
  }
  const now = new Date();
  const secs = Math.floor(now.getTime() / 1000) - unixTimeStamp;
  const days = Math.floor(secs / (24 * 60 * 60));
  if (days === 0 && maxDays > 0) {
    const mm = Math.floor(secs / 60) % 60;
    const hh = Math.floor(secs / (60 * 60));
    if (hh > 0) {
      /* if (mm > 0) {
        return hh + '小時' + mm + '分鐘前';
      } */
      return hh + '小時前';
    } else if (mm > 0) {
      return mm + '分鐘前';
    }
    return '剛剛';
  }

  const d = new Date(unixTimeStamp * 1000);
  let timePart = false;
  if (showTime === true) {
    timePart = ' ' + d.getHours() + ':';
    const mm = d.getMinutes();
    if (mm < 10) { timePart += '0'; }
    timePart += mm;
  }
  if (days > maxDays || maxDays === 0) {
    const yy = d.getFullYear();
    let s = '';
    if (yy !== now.getFullYear()) {
      s = yy;
      s += '-';
    }
    const MM = d.getMonth() + 1;
    if (MM < 10) { s += '0'; }
    s += MM;
    s += '-';
    const dd = d.getDate();
    if (dd < 10) { s += '0'; }
    s += dd;

    if (timePart) {
      s += timePart;
    }
    return s;
  }
  let rs;
  if (days === maxDays && maxDays > 2) {
    rs = days + '天前';
  } else if (days === 2) {
    rs = '前天';
  } else {
    rs = '昨天';
  }
  if (timePart) {
    rs += timePart;
  }
  return rs;
}

function requestAnimation (task) {
    if ('requestAnimationFrame' in window) {
        return window.requestAnimationFrame(task);
    }

    setTimeout(task, 16);
}

export { requestAnimation };

export default util;
