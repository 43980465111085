<template>
  <div class="home">
    <div id="stats">
      <Icon type="ios-stats" />
      {{$t('common.today')}}：{{stats.today}}
      <span class="seq">|</span>
      {{$t('common.yesterday')}}：{{stats.yesterday}}
      <span class="seq">|</span>
      {{$t('common.post')}}：{{stats.post_num}}
      <span class="seq">|</span>
      {{$t('common.user')}}：{{stats.user_num}}
      <span v-if="stats.last_user">
        <br v-if="narrow">
        <span v-else class="seq">|</span>
        {{$t('page.home.welcome_new')}}：<UserName :user="stats.last_user"></UserName>
      </span>
    </div>

    <div class="category" v-for="(category, ci) in categories" :key="ci">
      <div class="category-title">{{category.name}}</div>
      <div class="category-container" :style="category_container_style">
        <Card class="category-forum" :style="category_forum_style"
              v-for="(forum, fi) in category.forums" :key="fi">
          <p slot="title" :style="category_forum_title_style">
            <!-- 是否有新贴 -->
            <Icon v-if="forum.has_new" type="ios-chatboxes"></Icon>
            <Icon v-else type="ios-chatboxes-outline"></Icon>
            <router-link :to="{name:'forum', query:{id:forum.id}, params:{forum:forum}}">{{forum.name}}</router-link>
          </p>
          <!-- 今日主题数 -->
          <span slot="extra">{{forum.today_topics}}</span>

          <p>{{$t('page.home.lb_topic')}}：{{forum.topics}}, {{$t('page.home.lb_post_num')}}：{{forum.posts}}</p>
          <!-- 最后主题 -->
          <p v-if="forum.topic">
            <router-link :to="{name:'topic', query:{id: forum.topic.id}, params:{forum:forum, topic:forum.topic}}">
              {{forum.topic.subject}}
            </router-link>
            <br>
            <span style="font-size: smaller;">
              {{timeStamp(forum.topic.updated_at)}}&nbsp;
              <UserName :user="forum.topic.user"></UserName>
            </span>
          </p>
          <p v-else>......</p>
        </Card>
      </div>
    </div>

    <div v-show="loading" style="text-align: center;"><Spin size="large" style="display: inline-block;"></Spin>{{$t('page.data_loading')}}</div>
  </div>
</template>

<script>
  import { httpGet } from '@/api/data';
  import util from '@/libs/util';
  import UserName from './parts/user_name';

  export default {
    name: 'Home',
    components: {
      UserName
    },
    data () {
      return {
        inSoga: this.$store.state.inSoga,
        narrow: false,
        loading: true,
        category_container_style: '',
        category_forum_style: '',
        category_forum_title_style: '',
        category_data: null,
        categories: [],
        stats: {
          today: 0,
          yesterday: 0,
          post_num: 0,
          user_num: 0,
          last_user: null
        }
      }
    },
    methods: {
      initData () {
        // 获取数据
        httpGet('/v1/bbs/categories').then(res => {
          for (const i in res) {
            const c = res[i];
            for (const j in c.forums) {
              const forum = c.forums[j];
              if (forum.all_topics && forum.all_topics.length > 0) {
                forum.topic = forum.all_topics[0];
              } else {
                forum.today_topics = 0;
                forum.topics = 0;
                forum.posts = 0;
              }
              if (forum.topic) {
                if (forum.masters) {
                  forum.mids = [];
                  for (const i in forum.masters) {
                    forum.mids.push(forum.masters[i].id);
                  }
                }
                util.checkMaster(forum.topic.user, forum.mids);
              }
            }
          }
          this.category_data = res;
          this.refreshAuth();
        }).catch(() => {
        }).finally(() => {
          this.loading = false;
        });

        this.getStats();
      },
      getStats () {
        // 获取统计
        httpGet('/v1/bbs/stats').then(res => {
          this.stats = res;
        }).catch(() => {
        });
      },
      refreshAuth () {
        const data = this.category_data;
        const categories = [];
        for (const i in data) {
          const forums = [];
          for (const j in data[i].forums) {
            const forum = data[i].forums[j];
            const auth = util.userAuth(this.$store.state, forum);
            if (!auth.allowread) {
              continue;
            }
            forums.push(forum);
          }
          if (forums.length > 0) {
            const c = { ...data[i] };
            c.forums = forums;
            categories.push(c);
          }
          this.categories = categories;
        }
      },
      timeStamp (tt) {
        return util.showTimeStamp(tt);
      }
    },
    created () {
      this.narrow = window.screen.width < 730;
      if (this.narrow) {
        this.category_forum_style = 'width: 98%;';
      } else {
        const common = this.inSoga ? 'font-size: large;' : '';
        this.category_container_style = common + 'display: -webkit-flex; display: flex;';
        this.category_forum_style = common + 'width: 350px; display: inline-block;';
        this.category_forum_title_style = common;
      }
    },
    mounted () {
      this.initData();
    }
  };
</script>

<style scoped>
  #stats {
    text-align: left;
    width: 100%;
  }
  .category {
    border: lightblue solid 1px;
    margin-bottom: 10px;
  }
  .category-title {
    text-align: left;
    padding: 5px;
    color: darkblue;
    background: url("../assets/titlebg.png");
  }
  .category-container {
    text-align: left;
    flex-wrap: wrap;
  }
  .category-forum {
    margin: 5px;
    height: 150px;
    overflow: no-content;
  }
</style>
